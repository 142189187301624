export const table = [
  {
    atomicMass: "1.00794(4)",
    atomicNumber: "1",
    atomicRadius: "37",
    boilingPoint: "20",
    bondingType: "diatomic",
    cpkHexColor: "FFFFFF",
    density: "8.99E-5",
    electronAffinity: "-73",
    electronegativity: "2.2",
    electronicConfiguration: "1s1",
    groupBlock: "nonmetal",
    ionRadius: "",
    ionizationEnergy: "1312",
    meltingPoint: "14",
    name: "Hydrogen",
    oxidationStates: "-1, 1",
    standardState: "gas",
    symbol: "H",
    vanDelWaalsRadius: "120",
    yearDiscovered: "1766",
  },
  {
    atomicMass: "4.002602(2)",
    atomicNumber: "2",
    atomicRadius: "32",
    boilingPoint: "4",
    bondingType: "atomic",
    cpkHexColor: "D9FFFF",
    density: "1.785E-4",
    electronAffinity: "0",
    electronegativity: "",
    electronicConfiguration: "1s2",
    groupBlock: "noble gas",
    ionRadius: "",
    ionizationEnergy: "2372",
    meltingPoint: "",
    name: "Helium",
    oxidationStates: "",
    standardState: "gas",
    symbol: "He",
    vanDelWaalsRadius: "140",
    yearDiscovered: "1868",
  },
  {
    atomicMass: "6.941(2)",
    atomicNumber: "3",
    atomicRadius: "134",
    boilingPoint: "1615",
    bondingType: "metallic",
    cpkHexColor: "CC80FF",
    density: "0.535",
    electronAffinity: "-60",
    electronegativity: "0.98",
    electronicConfiguration: "[He] 2s1",
    groupBlock: "alkali metal",
    ionRadius: "76 (+1)",
    ionizationEnergy: "520",
    meltingPoint: "454",
    name: "Lithium",
    oxidationStates: "1",
    standardState: "solid",
    symbol: "Li",
    vanDelWaalsRadius: "182",
    yearDiscovered: "1817",
  },
  {
    atomicMass: "9.012182(3)",
    atomicNumber: "4",
    atomicRadius: "90",
    boilingPoint: "2743",
    bondingType: "metallic",
    cpkHexColor: "C2FF00",
    density: "1.848",
    electronAffinity: "0",
    electronegativity: "1.57",
    electronicConfiguration: "[He] 2s2",
    groupBlock: "alkaline earth metal",
    ionRadius: "45 (+2)",
    ionizationEnergy: "900",
    meltingPoint: "1560",
    name: "Beryllium",
    oxidationStates: "2",
    standardState: "solid",
    symbol: "Be",
    vanDelWaalsRadius: "",
    yearDiscovered: "1798",
  },
  {
    atomicMass: "10.811(7)",
    atomicNumber: "5",
    atomicRadius: "82",
    boilingPoint: "4273",
    bondingType: "covalent network",
    cpkHexColor: "FFB5B5",
    density: "2.46",
    electronAffinity: "-27",
    electronegativity: "2.04",
    electronicConfiguration: "[He] 2s2 2p1",
    groupBlock: "metalloid",
    ionRadius: "27 (+3)",
    ionizationEnergy: "801",
    meltingPoint: "2348",
    name: "Boron",
    oxidationStates: "1, 2, 3",
    standardState: "solid",
    symbol: "B",
    vanDelWaalsRadius: "",
    yearDiscovered: "1807",
  },
  {
    atomicMass: "12.0107(8)",
    atomicNumber: "6",
    atomicRadius: "77",
    boilingPoint: "4300",
    bondingType: "covalent network",
    cpkHexColor: "909090",
    density: "2.26",
    electronAffinity: "-154",
    electronegativity: "2.55",
    electronicConfiguration: "[He] 2s2 2p2",
    groupBlock: "nonmetal",
    ionRadius: "16 (+4)",
    ionizationEnergy: "1087",
    meltingPoint: "3823",
    name: "Carbon",
    oxidationStates: "-4, -3, -2, -1, 1, 2, 3, 4",
    standardState: "solid",
    symbol: "C",
    vanDelWaalsRadius: "170",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "14.0067(2)",
    atomicNumber: "7",
    atomicRadius: "75",
    boilingPoint: "77",
    bondingType: "diatomic",
    cpkHexColor: "3050F8",
    density: "0.001251",
    electronAffinity: "-7",
    electronegativity: "3.04",
    electronicConfiguration: "[He] 2s2 2p3",
    groupBlock: "nonmetal",
    ionRadius: "146 (-3)",
    ionizationEnergy: "1402",
    meltingPoint: "63",
    name: "Nitrogen",
    oxidationStates: "-3, -2, -1, 1, 2, 3, 4, 5",
    standardState: "gas",
    symbol: "N",
    vanDelWaalsRadius: "155",
    yearDiscovered: "1772",
  },
  {
    atomicMass: "15.9994(3)",
    atomicNumber: "8",
    atomicRadius: "73",
    boilingPoint: "90",
    bondingType: "diatomic",
    cpkHexColor: "FF0D0D",
    density: "0.001429",
    electronAffinity: "-141",
    electronegativity: "3.44",
    electronicConfiguration: "[He] 2s2 2p4",
    groupBlock: "nonmetal",
    ionRadius: "140 (-2)",
    ionizationEnergy: "1314",
    meltingPoint: "55",
    name: "Oxygen",
    oxidationStates: "-2, -1, 1, 2",
    standardState: "gas",
    symbol: "O",
    vanDelWaalsRadius: "152",
    yearDiscovered: "1774",
  },
  {
    atomicMass: "18.9984032(5)",
    atomicNumber: "9",
    atomicRadius: "71",
    boilingPoint: "85",
    bondingType: "atomic",
    cpkHexColor: "9.0E51",
    density: "0.001696",
    electronAffinity: "-328",
    electronegativity: "3.98",
    electronicConfiguration: "[He] 2s2 2p5",
    groupBlock: "halogen",
    ionRadius: "133 (-1)",
    ionizationEnergy: "1681",
    meltingPoint: "54",
    name: "Fluorine",
    oxidationStates: "-1",
    standardState: "gas",
    symbol: "F",
    vanDelWaalsRadius: "147",
    yearDiscovered: "1670",
  },
  {
    atomicMass: "20.1797(6)",
    atomicNumber: "10",
    atomicRadius: "69",
    boilingPoint: "27",
    bondingType: "atomic",
    cpkHexColor: "B3E3F5",
    density: "9.0E-4",
    electronAffinity: "0",
    electronegativity: "",
    electronicConfiguration: "[He] 2s2 2p6",
    groupBlock: "noble gas",
    ionRadius: "",
    ionizationEnergy: "2081",
    meltingPoint: "25",
    name: "Neon",
    oxidationStates: "",
    standardState: "gas",
    symbol: "Ne",
    vanDelWaalsRadius: "154",
    yearDiscovered: "1898",
  },
  {
    atomicMass: "22.98976928(2)",
    atomicNumber: "11",
    atomicRadius: "154",
    boilingPoint: "1156",
    bondingType: "metallic",
    cpkHexColor: "AB5CF2",
    density: "0.968",
    electronAffinity: "-53",
    electronegativity: "0.93",
    electronicConfiguration: "[Ne] 3s1",
    groupBlock: "alkali metal",
    ionRadius: "102 (+1)",
    ionizationEnergy: "496",
    meltingPoint: "371",
    name: "Sodium",
    oxidationStates: "-1, 1",
    standardState: "solid",
    symbol: "Na",
    vanDelWaalsRadius: "227",
    yearDiscovered: "1807",
  },
  {
    atomicMass: "24.3050(6)",
    atomicNumber: "12",
    atomicRadius: "130",
    boilingPoint: "1363",
    bondingType: "metallic",
    cpkHexColor: "8AFF00",
    density: "1.738",
    electronAffinity: "0",
    electronegativity: "1.31",
    electronicConfiguration: "[Ne] 3s2",
    groupBlock: "alkaline earth metal",
    ionRadius: "72 (+2)",
    ionizationEnergy: "738",
    meltingPoint: "923",
    name: "Magnesium",
    oxidationStates: "1, 2",
    standardState: "solid",
    symbol: "Mg",
    vanDelWaalsRadius: "173",
    yearDiscovered: "1808",
  },
  {
    atomicMass: "26.9815386(8)",
    atomicNumber: "13",
    atomicRadius: "118",
    boilingPoint: "2792",
    bondingType: "metallic",
    cpkHexColor: "BFA6A6",
    density: "2.7",
    electronAffinity: "-43",
    electronegativity: "1.61",
    electronicConfiguration: "[Ne] 3s2 3p1",
    groupBlock: "metal",
    ionRadius: "53.5 (+3)",
    ionizationEnergy: "578",
    meltingPoint: "933",
    name: "Aluminum",
    oxidationStates: "1, 3",
    standardState: "solid",
    symbol: "Al",
    vanDelWaalsRadius: "",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "28.0855(3)",
    atomicNumber: "14",
    atomicRadius: "111",
    boilingPoint: "3173",
    bondingType: "metallic",
    cpkHexColor: "F0C8A0",
    density: "2.33",
    electronAffinity: "-134",
    electronegativity: "1.9",
    electronicConfiguration: "[Ne] 3s2 3p2",
    groupBlock: "metalloid",
    ionRadius: "40 (+4)",
    ionizationEnergy: "787",
    meltingPoint: "1687",
    name: "Silicon",
    oxidationStates: "-4, -3, -2, -1, 1, 2, 3, 4",
    standardState: "solid",
    symbol: "Si",
    vanDelWaalsRadius: "210",
    yearDiscovered: "1854",
  },
  {
    atomicMass: "30.973762(2)",
    atomicNumber: "15",
    atomicRadius: "106",
    boilingPoint: "554",
    bondingType: "covalent network",
    cpkHexColor: "FF8000",
    density: "1.823",
    electronAffinity: "-72",
    electronegativity: "2.19",
    electronicConfiguration: "[Ne] 3s2 3p3",
    groupBlock: "nonmetal",
    ionRadius: "44 (+3)",
    ionizationEnergy: "1012",
    meltingPoint: "317",
    name: "Phosphorus",
    oxidationStates: "-3, -2, -1, 1, 2, 3, 4, 5",
    standardState: "solid",
    symbol: "P",
    vanDelWaalsRadius: "180",
    yearDiscovered: "1669",
  },
  {
    atomicMass: "32.065(5)",
    atomicNumber: "16",
    atomicRadius: "102",
    boilingPoint: "718",
    bondingType: "covalent network",
    cpkHexColor: "FFFF30",
    density: "1.96",
    electronAffinity: "-200",
    electronegativity: "2.58",
    electronicConfiguration: "[Ne] 3s2 3p4",
    groupBlock: "nonmetal",
    ionRadius: "184 (-2)",
    ionizationEnergy: "1000",
    meltingPoint: "388",
    name: "Sulfur",
    oxidationStates: "-2, -1, 1, 2, 3, 4, 5, 6",
    standardState: "solid",
    symbol: "S",
    vanDelWaalsRadius: "180",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "35.453(2)",
    atomicNumber: "17",
    atomicRadius: "99",
    boilingPoint: "239",
    bondingType: "covalent network",
    cpkHexColor: "1FF01F",
    density: "0.003214",
    electronAffinity: "-349",
    electronegativity: "3.16",
    electronicConfiguration: "[Ne] 3s2 3p5",
    groupBlock: "halogen",
    ionRadius: "181 (-1)",
    ionizationEnergy: "1251",
    meltingPoint: "172",
    name: "Chlorine",
    oxidationStates: "-1, 1, 2, 3, 4, 5, 6, 7",
    standardState: "gas",
    symbol: "Cl",
    vanDelWaalsRadius: "175",
    yearDiscovered: "1774",
  },
  {
    atomicMass: "39.948(1)",
    atomicNumber: "18",
    atomicRadius: "97",
    boilingPoint: "87",
    bondingType: "atomic",
    cpkHexColor: "80D1E3",
    density: "0.001784",
    electronAffinity: "0",
    electronegativity: "",
    electronicConfiguration: "[Ne] 3s2 3p6",
    groupBlock: "noble gas",
    ionRadius: "",
    ionizationEnergy: "1521",
    meltingPoint: "84",
    name: "Argon",
    oxidationStates: "",
    standardState: "gas",
    symbol: "Ar",
    vanDelWaalsRadius: "188",
    yearDiscovered: "1894",
  },
  {
    atomicMass: "39.0983(1)",
    atomicNumber: "19",
    atomicRadius: "196",
    boilingPoint: "1032",
    bondingType: "metallic",
    cpkHexColor: "8F40D4",
    density: "0.856",
    electronAffinity: "-48",
    electronegativity: "0.82",
    electronicConfiguration: "[Ar] 4s1",
    groupBlock: "alkali metal",
    ionRadius: "138 (+1)",
    ionizationEnergy: "419",
    meltingPoint: "337",
    name: "Potassium",
    oxidationStates: "1",
    standardState: "solid",
    symbol: "K",
    vanDelWaalsRadius: "275",
    yearDiscovered: "1807",
  },
  {
    atomicMass: "40.078(4)",
    atomicNumber: "20",
    atomicRadius: "174",
    boilingPoint: "1757",
    bondingType: "metallic",
    cpkHexColor: "3DFF00",
    density: "1.55",
    electronAffinity: "-2",
    electronegativity: "1",
    electronicConfiguration: "[Ar] 4s2",
    groupBlock: "alkaline earth metal",
    ionRadius: "100 (+2)",
    ionizationEnergy: "590",
    meltingPoint: "1115",
    name: "Calcium",
    oxidationStates: "2",
    standardState: "solid",
    symbol: "Ca",
    vanDelWaalsRadius: "",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "44.955912(6)",
    atomicNumber: "21",
    atomicRadius: "144",
    boilingPoint: "3103",
    bondingType: "metallic",
    cpkHexColor: "E6E6E6",
    density: "2.985",
    electronAffinity: "-18",
    electronegativity: "1.36",
    electronicConfiguration: "[Ar] 3d1 4s2",
    groupBlock: "transition metal",
    ionRadius: "74.5 (+3)",
    ionizationEnergy: "633",
    meltingPoint: "1814",
    name: "Scandium",
    oxidationStates: "1, 2, 3",
    standardState: "solid",
    symbol: "Sc",
    vanDelWaalsRadius: "",
    yearDiscovered: "1876",
  },
  {
    atomicMass: "47.867(1)",
    atomicNumber: "22",
    atomicRadius: "136",
    boilingPoint: "3560",
    bondingType: "metallic",
    cpkHexColor: "BFC2C7",
    density: "4.507",
    electronAffinity: "-8",
    electronegativity: "1.54",
    electronicConfiguration: "[Ar] 3d2 4s2",
    groupBlock: "transition metal",
    ionRadius: "86 (+2)",
    ionizationEnergy: "659",
    meltingPoint: "1941",
    name: "Titanium",
    oxidationStates: "-1, 2, 3, 4",
    standardState: "solid",
    symbol: "Ti",
    vanDelWaalsRadius: "",
    yearDiscovered: "1791",
  },
  {
    atomicMass: "50.9415(1)",
    atomicNumber: "23",
    atomicRadius: "125",
    boilingPoint: "3680",
    bondingType: "metallic",
    cpkHexColor: "A6A6AB",
    density: "6.11",
    electronAffinity: "-51",
    electronegativity: "1.63",
    electronicConfiguration: "[Ar] 3d3 4s2",
    groupBlock: "transition metal",
    ionRadius: "79 (+2)",
    ionizationEnergy: "651",
    meltingPoint: "2183",
    name: "Vanadium",
    oxidationStates: "-1, 2, 3, 4",
    standardState: "solid",
    symbol: "V",
    vanDelWaalsRadius: "",
    yearDiscovered: "1803",
  },
  {
    atomicMass: "51.9961(6)",
    atomicNumber: "24",
    atomicRadius: "127",
    boilingPoint: "2944",
    bondingType: "metallic",
    cpkHexColor: "8A99C7",
    density: "7.14",
    electronAffinity: "-64",
    electronegativity: "1.66",
    electronicConfiguration: "[Ar] 3d5 4s1",
    groupBlock: "transition metal",
    ionRadius: "80 (+2*)",
    ionizationEnergy: "653",
    meltingPoint: "2180",
    name: "Chromium",
    oxidationStates: "-2, -1, 1, 2, 3, 4, 5, 6",
    standardState: "solid",
    symbol: "Cr",
    vanDelWaalsRadius: "",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "54.938045(5)",
    atomicNumber: "25",
    atomicRadius: "139",
    boilingPoint: "2334",
    bondingType: "metallic",
    cpkHexColor: "9C7AC7",
    density: "7.47",
    electronAffinity: "0",
    electronegativity: "1.55",
    electronicConfiguration: "[Ar] 3d5 4s2",
    groupBlock: "transition metal",
    ionRadius: "67 (+2)",
    ionizationEnergy: "717",
    meltingPoint: "1519",
    name: "Manganese",
    oxidationStates: "-3, -2, -1, 1, 2, 3, 4, 5, 6, 7",
    standardState: "solid",
    symbol: "Mn",
    vanDelWaalsRadius: "",
    yearDiscovered: "1774",
  },
  {
    atomicMass: "55.845(2)",
    atomicNumber: "26",
    atomicRadius: "125",
    boilingPoint: "3134",
    bondingType: "metallic",
    cpkHexColor: "E06633",
    density: "7.874",
    electronAffinity: "-16",
    electronegativity: "1.83",
    electronicConfiguration: "[Ar] 3d6 4s2",
    groupBlock: "transition metal",
    ionRadius: "78 (+2*)",
    ionizationEnergy: "763",
    meltingPoint: "1811",
    name: "Iron",
    oxidationStates: "-2, -1, 1, 2, 3, 4, 5, 6",
    standardState: "solid",
    symbol: "Fe",
    vanDelWaalsRadius: "",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "58.933195(5)",
    atomicNumber: "27",
    atomicRadius: "126",
    boilingPoint: "3200",
    bondingType: "metallic",
    cpkHexColor: "F090A0",
    density: "8.9",
    electronAffinity: "-64",
    electronegativity: "1.88",
    electronicConfiguration: "[Ar] 3d7 4s2",
    groupBlock: "transition metal",
    ionRadius: "74.5 (+2*)",
    ionizationEnergy: "760",
    meltingPoint: "1768",
    name: "Cobalt",
    oxidationStates: "-1, 1, 2, 3, 4, 5",
    standardState: "solid",
    symbol: "Co",
    vanDelWaalsRadius: "",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "58.6934(4)",
    atomicNumber: "28",
    atomicRadius: "121",
    boilingPoint: "3186",
    bondingType: "metallic",
    cpkHexColor: "50D050",
    density: "8.908",
    electronAffinity: "-112",
    electronegativity: "1.91",
    electronicConfiguration: "[Ar] 3d8 4s2",
    groupBlock: "transition metal",
    ionRadius: "69 (+2)",
    ionizationEnergy: "737",
    meltingPoint: "1728",
    name: "Nickel",
    oxidationStates: "-1, 1, 2, 3, 4",
    standardState: "solid",
    symbol: "Ni",
    vanDelWaalsRadius: "163",
    yearDiscovered: "1751",
  },
  {
    atomicMass: "63.546(3)",
    atomicNumber: "29",
    atomicRadius: "138",
    boilingPoint: "3200",
    bondingType: "metallic",
    cpkHexColor: "C88033",
    density: "8.92",
    electronAffinity: "-118",
    electronegativity: "1.9",
    electronicConfiguration: "[Ar] 3d10 4s1",
    groupBlock: "transition metal",
    ionRadius: "77 (+1)",
    ionizationEnergy: "746",
    meltingPoint: "1358",
    name: "Copper",
    oxidationStates: "1, 2, 3, 4",
    standardState: "solid",
    symbol: "Cu",
    vanDelWaalsRadius: "140",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "65.38(2)",
    atomicNumber: "30",
    atomicRadius: "131",
    boilingPoint: "1180",
    bondingType: "metallic",
    cpkHexColor: "7D80B0",
    density: "7.14",
    electronAffinity: "0",
    electronegativity: "1.65",
    electronicConfiguration: "[Ar] 3d10 4s2",
    groupBlock: "transition metal",
    ionRadius: "74 (+2)",
    ionizationEnergy: "906",
    meltingPoint: "693",
    name: "Zinc",
    oxidationStates: "2",
    standardState: "solid",
    symbol: "Zn",
    vanDelWaalsRadius: "139",
    yearDiscovered: "1746",
  },
  {
    atomicMass: "69.723(1)",
    atomicNumber: "31",
    atomicRadius: "126",
    boilingPoint: "2477",
    bondingType: "metallic",
    cpkHexColor: "C28F8F",
    density: "5.904",
    electronAffinity: "-29",
    electronegativity: "1.81",
    electronicConfiguration: "[Ar] 3d10 4s2 4p1",
    groupBlock: "metal",
    ionRadius: "62 (+3)",
    ionizationEnergy: "579",
    meltingPoint: "303",
    name: "Gallium",
    oxidationStates: "1, 2, 3",
    standardState: "solid",
    symbol: "Ga",
    vanDelWaalsRadius: "187",
    yearDiscovered: "1875",
  },
  {
    atomicMass: "72.64(1)",
    atomicNumber: "32",
    atomicRadius: "122",
    boilingPoint: "3093",
    bondingType: "metallic",
    cpkHexColor: "668F8F",
    density: "5.323",
    electronAffinity: "-119",
    electronegativity: "2.01",
    electronicConfiguration: "[Ar] 3d10 4s2 4p2",
    groupBlock: "metalloid",
    ionRadius: "73 (+2)",
    ionizationEnergy: "762",
    meltingPoint: "1211",
    name: "Germanium",
    oxidationStates: "-4, 1, 2, 3, 4",
    standardState: "solid",
    symbol: "Ge",
    vanDelWaalsRadius: "",
    yearDiscovered: "1886",
  },
  {
    atomicMass: "74.92160(2)",
    atomicNumber: "33",
    atomicRadius: "119",
    boilingPoint: "887",
    bondingType: "metallic",
    cpkHexColor: "BD80E3",
    density: "5.727",
    electronAffinity: "-78",
    electronegativity: "2.18",
    electronicConfiguration: "[Ar] 3d10 4s2 4p3",
    groupBlock: "metalloid",
    ionRadius: "58 (+3)",
    ionizationEnergy: "947",
    meltingPoint: "1090",
    name: "Arsenic",
    oxidationStates: "-3, 2, 3, 5",
    standardState: "solid",
    symbol: "As",
    vanDelWaalsRadius: "185",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "78.96(3)",
    atomicNumber: "34",
    atomicRadius: "116",
    boilingPoint: "958",
    bondingType: "metallic",
    cpkHexColor: "FFA100",
    density: "4.819",
    electronAffinity: "-195",
    electronegativity: "2.55",
    electronicConfiguration: "[Ar] 3d10 4s2 4p4",
    groupBlock: "nonmetal",
    ionRadius: "198 (-2)",
    ionizationEnergy: "941",
    meltingPoint: "494",
    name: "Selenium",
    oxidationStates: "-2, 2, 4, 6",
    standardState: "solid",
    symbol: "Se",
    vanDelWaalsRadius: "190",
    yearDiscovered: "1817",
  },
  {
    atomicMass: "79.904(1)",
    atomicNumber: "35",
    atomicRadius: "114",
    boilingPoint: "332",
    bondingType: "covalent network",
    cpkHexColor: "A62929",
    density: "3.12",
    electronAffinity: "-325",
    electronegativity: "2.96",
    electronicConfiguration: "[Ar] 3d10 4s2 4p5",
    groupBlock: "halogen",
    ionRadius: "196 (-1)",
    ionizationEnergy: "1140",
    meltingPoint: "266",
    name: "Bromine",
    oxidationStates: "-1, 1, 3, 4, 5, 7",
    standardState: "liquid",
    symbol: "Br",
    vanDelWaalsRadius: "185",
    yearDiscovered: "1826",
  },
  {
    atomicMass: "83.798(2)",
    atomicNumber: "36",
    atomicRadius: "110",
    boilingPoint: "120",
    bondingType: "atomic",
    cpkHexColor: "5CB8D1",
    density: "0.00375",
    electronAffinity: "0",
    electronegativity: "",
    electronicConfiguration: "[Ar] 3d10 4s2 4p6",
    groupBlock: "noble gas",
    ionRadius: "",
    ionizationEnergy: "1351",
    meltingPoint: "116",
    name: "Krypton",
    oxidationStates: "2",
    standardState: "gas",
    symbol: "Kr",
    vanDelWaalsRadius: "202",
    yearDiscovered: "1898",
  },
  {
    atomicMass: "85.4678(3)",
    atomicNumber: "37",
    atomicRadius: "211",
    boilingPoint: "961",
    bondingType: "metallic",
    cpkHexColor: "702EB0",
    density: "1.532",
    electronAffinity: "-47",
    electronegativity: "0.82",
    electronicConfiguration: "[Kr] 5s1",
    groupBlock: "alkali metal",
    ionRadius: "152 (+1)",
    ionizationEnergy: "403",
    meltingPoint: "312",
    name: "Rubidium",
    oxidationStates: "1",
    standardState: "solid",
    symbol: "Rb",
    vanDelWaalsRadius: "",
    yearDiscovered: "1861",
  },
  {
    atomicMass: "87.62(1)",
    atomicNumber: "38",
    atomicRadius: "192",
    boilingPoint: "1655",
    bondingType: "metallic",
    cpkHexColor: "00FF00",
    density: "2.63",
    electronAffinity: "-5",
    electronegativity: "0.95",
    electronicConfiguration: "[Kr] 5s2",
    groupBlock: "alkaline earth metal",
    ionRadius: "118 (+2)",
    ionizationEnergy: "550",
    meltingPoint: "1050",
    name: "Strontium",
    oxidationStates: "2",
    standardState: "solid",
    symbol: "Sr",
    vanDelWaalsRadius: "",
    yearDiscovered: "1790",
  },
  {
    atomicMass: "88.90585(2)",
    atomicNumber: "39",
    atomicRadius: "162",
    boilingPoint: "3618",
    bondingType: "metallic",
    cpkHexColor: "94FFFF",
    density: "4.472",
    electronAffinity: "-30",
    electronegativity: "1.22",
    electronicConfiguration: "[Kr] 4d1 5s2",
    groupBlock: "transition metal",
    ionRadius: "90 (+3)",
    ionizationEnergy: "600",
    meltingPoint: "1799",
    name: "Yttrium",
    oxidationStates: "1, 2, 3",
    standardState: "solid",
    symbol: "Y",
    vanDelWaalsRadius: "",
    yearDiscovered: "1794",
  },
  {
    atomicMass: "91.224(2)",
    atomicNumber: "40",
    atomicRadius: "148",
    boilingPoint: "4682",
    bondingType: "metallic",
    cpkHexColor: "94E0E0",
    density: "6.511",
    electronAffinity: "-41",
    electronegativity: "1.33",
    electronicConfiguration: "[Kr] 4d2 5s2",
    groupBlock: "transition metal",
    ionRadius: "72 (+4)",
    ionizationEnergy: "640",
    meltingPoint: "2128",
    name: "Zirconium",
    oxidationStates: "1, 2, 3, 4",
    standardState: "solid",
    symbol: "Zr",
    vanDelWaalsRadius: "",
    yearDiscovered: "1789",
  },
  {
    atomicMass: "92.90638(2)",
    atomicNumber: "41",
    atomicRadius: "137",
    boilingPoint: "5017",
    bondingType: "metallic",
    cpkHexColor: "73C2C9",
    density: "8.57",
    electronAffinity: "-86",
    electronegativity: "1.6",
    electronicConfiguration: "[Kr] 4d4 5s1",
    groupBlock: "transition metal",
    ionRadius: "72 (+3)",
    ionizationEnergy: "652",
    meltingPoint: "2750",
    name: "Niobium",
    oxidationStates: "-1, 2, 3, 4, 5",
    standardState: "solid",
    symbol: "Nb",
    vanDelWaalsRadius: "",
    yearDiscovered: "1801",
  },
  {
    atomicMass: "95.96(2)",
    atomicNumber: "42",
    atomicRadius: "145",
    boilingPoint: "4912",
    bondingType: "metallic",
    cpkHexColor: "54B5B5",
    density: "10.28",
    electronAffinity: "-72",
    electronegativity: "2.16",
    electronicConfiguration: "[Kr] 4d5 5s1",
    groupBlock: "transition metal",
    ionRadius: "69 (+3)",
    ionizationEnergy: "684",
    meltingPoint: "2896",
    name: "Molybdenum",
    oxidationStates: "-2, -1, 1, 2, 3, 4, 5, 6",
    standardState: "solid",
    symbol: "Mo",
    vanDelWaalsRadius: "",
    yearDiscovered: "1778",
  },
  {
    atomicMass: "98",
    atomicNumber: "43",
    atomicRadius: "156",
    boilingPoint: "4538",
    bondingType: "metallic",
    cpkHexColor: "3B9E9E",
    density: "11.5",
    electronAffinity: "-53",
    electronegativity: "1.9",
    electronicConfiguration: "[Kr] 4d5 5s2",
    groupBlock: "transition metal",
    ionRadius: "64.5 (+4)",
    ionizationEnergy: "702",
    meltingPoint: "2430",
    name: "Technetium",
    oxidationStates: "-3, -1, 1, 2, 3, 4, 5, 6, 7",
    standardState: "solid",
    symbol: "Tc",
    vanDelWaalsRadius: "",
    yearDiscovered: "1937",
  },
  {
    atomicMass: "101.07(2)",
    atomicNumber: "44",
    atomicRadius: "126",
    boilingPoint: "4423",
    bondingType: "metallic",
    cpkHexColor: "248F8F",
    density: "12.37",
    electronAffinity: "-101",
    electronegativity: "2.2",
    electronicConfiguration: "[Kr] 4d7 5s1",
    groupBlock: "transition metal",
    ionRadius: "68 (+3)",
    ionizationEnergy: "710",
    meltingPoint: "2607",
    name: "Ruthenium",
    oxidationStates: "-2, 1, 2, 3, 4, 5, 6, 7, 8",
    standardState: "solid",
    symbol: "Ru",
    vanDelWaalsRadius: "",
    yearDiscovered: "1827",
  },
  {
    atomicMass: "102.90550(2)",
    atomicNumber: "45",
    atomicRadius: "135",
    boilingPoint: "3968",
    bondingType: "metallic",
    cpkHexColor: "0A7D8C",
    density: "12.45",
    electronAffinity: "-110",
    electronegativity: "2.28",
    electronicConfiguration: "[Kr] 4d8 5s1",
    groupBlock: "transition metal",
    ionRadius: "66.5 (+3)",
    ionizationEnergy: "720",
    meltingPoint: "2237",
    name: "Rhodium",
    oxidationStates: "-1, 1, 2, 3, 4, 5, 6",
    standardState: "solid",
    symbol: "Rh",
    vanDelWaalsRadius: "",
    yearDiscovered: "1803",
  },
  {
    atomicMass: "106.42(1)",
    atomicNumber: "46",
    atomicRadius: "131",
    boilingPoint: "3236",
    bondingType: "metallic",
    cpkHexColor: "6985",
    density: "12.023",
    electronAffinity: "-54",
    electronegativity: "2.2",
    electronicConfiguration: "[Kr] 4d10",
    groupBlock: "transition metal",
    ionRadius: "59 (+1)",
    ionizationEnergy: "804",
    meltingPoint: "1828",
    name: "Palladium",
    oxidationStates: "2, 4",
    standardState: "solid",
    symbol: "Pd",
    vanDelWaalsRadius: "163",
    yearDiscovered: "1803",
  },
  {
    atomicMass: "107.8682(2)",
    atomicNumber: "47",
    atomicRadius: "153",
    boilingPoint: "2435",
    bondingType: "metallic",
    cpkHexColor: "C0C0C0",
    density: "10.49",
    electronAffinity: "-126",
    electronegativity: "1.93",
    electronicConfiguration: "[Kr] 4d10 5s1",
    groupBlock: "transition metal",
    ionRadius: "115 (+1)",
    ionizationEnergy: "731",
    meltingPoint: "1235",
    name: "Silver",
    oxidationStates: "1, 2, 3",
    standardState: "solid",
    symbol: "Ag",
    vanDelWaalsRadius: "172",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "112.411(8)",
    atomicNumber: "48",
    atomicRadius: "148",
    boilingPoint: "1040",
    bondingType: "metallic",
    cpkHexColor: "FFD98F",
    density: "8.65",
    electronAffinity: "0",
    electronegativity: "1.69",
    electronicConfiguration: "[Kr] 4d10 5s2",
    groupBlock: "transition metal",
    ionRadius: "95 (+2)",
    ionizationEnergy: "868",
    meltingPoint: "594",
    name: "Cadmium",
    oxidationStates: "2",
    standardState: "solid",
    symbol: "Cd",
    vanDelWaalsRadius: "158",
    yearDiscovered: "1817",
  },
  {
    atomicMass: "114.818(3)",
    atomicNumber: "49",
    atomicRadius: "144",
    boilingPoint: "2345",
    bondingType: "metallic",
    cpkHexColor: "A67573",
    density: "7.31",
    electronAffinity: "-29",
    electronegativity: "1.78",
    electronicConfiguration: "[Kr] 4d10 5s2 5p1",
    groupBlock: "metal",
    ionRadius: "80 (+3)",
    ionizationEnergy: "558",
    meltingPoint: "430",
    name: "Indium",
    oxidationStates: "1, 2, 3",
    standardState: "solid",
    symbol: "In",
    vanDelWaalsRadius: "193",
    yearDiscovered: "1863",
  },
  {
    atomicMass: "118.710(7)",
    atomicNumber: "50",
    atomicRadius: "141",
    boilingPoint: "2875",
    bondingType: "metallic",
    cpkHexColor: "668080",
    density: "7.31",
    electronAffinity: "-107",
    electronegativity: "1.96",
    electronicConfiguration: "[Kr] 4d10 5s2 5p2",
    groupBlock: "metal",
    ionRadius: "112 (+2)",
    ionizationEnergy: "709",
    meltingPoint: "505",
    name: "Tin",
    oxidationStates: "-4, 2, 4",
    standardState: "solid",
    symbol: "Sn",
    vanDelWaalsRadius: "217",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "121.760(1)",
    atomicNumber: "51",
    atomicRadius: "138",
    boilingPoint: "1860",
    bondingType: "metallic",
    cpkHexColor: "9E63B5",
    density: "6.697",
    electronAffinity: "-103",
    electronegativity: "2.05",
    electronicConfiguration: "[Kr] 4d10 5s2 5p3",
    groupBlock: "metalloid",
    ionRadius: "76 (+3)",
    ionizationEnergy: "834",
    meltingPoint: "904",
    name: "Antimony",
    oxidationStates: "-3, 3, 5",
    standardState: "solid",
    symbol: "Sb",
    vanDelWaalsRadius: "",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "127.60(3)",
    atomicNumber: "52",
    atomicRadius: "135",
    boilingPoint: "1261",
    bondingType: "metallic",
    cpkHexColor: "D47A00",
    density: "6.24",
    electronAffinity: "-190",
    electronegativity: "2.1",
    electronicConfiguration: "[Kr] 4d10 5s2 5p4",
    groupBlock: "metalloid",
    ionRadius: "221 (-2)",
    ionizationEnergy: "869",
    meltingPoint: "723",
    name: "Tellurium",
    oxidationStates: "-2, 2, 4, 5, 6",
    standardState: "solid",
    symbol: "Te",
    vanDelWaalsRadius: "206",
    yearDiscovered: "1782",
  },
  {
    atomicMass: "126.90447(3)",
    atomicNumber: "53",
    atomicRadius: "133",
    boilingPoint: "457",
    bondingType: "covalent network",
    cpkHexColor: "940094",
    density: "4.94",
    electronAffinity: "-295",
    electronegativity: "2.66",
    electronicConfiguration: "[Kr] 4d10 5s2 5p5",
    groupBlock: "halogen",
    ionRadius: "220 (-1)",
    ionizationEnergy: "1008",
    meltingPoint: "387",
    name: "Iodine",
    oxidationStates: "-1, 1, 3, 5, 7",
    standardState: "solid",
    symbol: "I",
    vanDelWaalsRadius: "198",
    yearDiscovered: "1811",
  },
  {
    atomicMass: "131.293(6)",
    atomicNumber: "54",
    atomicRadius: "130",
    boilingPoint: "165",
    bondingType: "atomic",
    cpkHexColor: "429EB0",
    density: "0.0059",
    electronAffinity: "0",
    electronegativity: "",
    electronicConfiguration: "[Kr] 4d10 5s2 5p6",
    groupBlock: "noble gas",
    ionRadius: "48 (+8)",
    ionizationEnergy: "1170",
    meltingPoint: "161",
    name: "Xenon",
    oxidationStates: "2, 4, 6, 8",
    standardState: "gas",
    symbol: "Xe",
    vanDelWaalsRadius: "216",
    yearDiscovered: "1898",
  },
  {
    atomicMass: "132.9054519(2)",
    atomicNumber: "55",
    atomicRadius: "225",
    boilingPoint: "944",
    bondingType: "metallic",
    cpkHexColor: "57178F",
    density: "1.879",
    electronAffinity: "-46",
    electronegativity: "0.79",
    electronicConfiguration: "[Xe] 6s1",
    groupBlock: "alkali metal",
    ionRadius: "167 (+1)",
    ionizationEnergy: "376",
    meltingPoint: "302",
    name: "Cesium",
    oxidationStates: "1",
    standardState: "solid",
    symbol: "Cs",
    vanDelWaalsRadius: "",
    yearDiscovered: "1860",
  },
  {
    atomicMass: "137.327(7)",
    atomicNumber: "56",
    atomicRadius: "198",
    boilingPoint: "2143",
    bondingType: "metallic",
    cpkHexColor: "00C900",
    density: "3.51",
    electronAffinity: "-14",
    electronegativity: "0.89",
    electronicConfiguration: "[Xe] 6s2",
    groupBlock: "alkaline earth metal",
    ionRadius: "135 (+2)",
    ionizationEnergy: "503",
    meltingPoint: "1000",
    name: "Barium",
    oxidationStates: "2",
    standardState: "solid",
    symbol: "Ba",
    vanDelWaalsRadius: "",
    yearDiscovered: "1808",
  },
  {
    atomicMass: "138.90547(7)",
    atomicNumber: "57",
    atomicRadius: "169",
    boilingPoint: "3737",
    bondingType: "metallic",
    cpkHexColor: "70D4FF",
    density: "6.146",
    electronAffinity: "-48",
    electronegativity: "1.1",
    electronicConfiguration: "[Xe] 5d1 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "103.2 (+3)",
    ionizationEnergy: "538",
    meltingPoint: "1193",
    name: "Lanthanum",
    oxidationStates: "2, 3",
    standardState: "solid",
    symbol: "La",
    vanDelWaalsRadius: "",
    yearDiscovered: "1839",
  },
  {
    atomicMass: "140.116(1)",
    atomicNumber: "58",
    atomicRadius: "",
    boilingPoint: "3633",
    bondingType: "metallic",
    cpkHexColor: "FFFFC7",
    density: "6.689",
    electronAffinity: "-50",
    electronegativity: "1.12",
    electronicConfiguration: "[Xe] 4f1 5d1 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "102 (+3)",
    ionizationEnergy: "534",
    meltingPoint: "1071",
    name: "Cerium",
    oxidationStates: "2, 3, 4",
    standardState: "solid",
    symbol: "Ce",
    vanDelWaalsRadius: "",
    yearDiscovered: "1803",
  },
  {
    atomicMass: "140.90765(2)",
    atomicNumber: "59",
    atomicRadius: "",
    boilingPoint: "3563",
    bondingType: "metallic",
    cpkHexColor: "D9FFC7",
    density: "6.64",
    electronAffinity: "-50",
    electronegativity: "1.13",
    electronicConfiguration: "[Xe] 4f3 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "99 (+3)",
    ionizationEnergy: "527",
    meltingPoint: "1204",
    name: "Praseodymium",
    oxidationStates: "2, 3, 4",
    standardState: "solid",
    symbol: "Pr",
    vanDelWaalsRadius: "",
    yearDiscovered: "1885",
  },
  {
    atomicMass: "144.242(3)",
    atomicNumber: "60",
    atomicRadius: "",
    boilingPoint: "3373",
    bondingType: "metallic",
    cpkHexColor: "C7FFC7",
    density: "7.01",
    electronAffinity: "-50",
    electronegativity: "1.14",
    electronicConfiguration: "[Xe] 4f4 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "129 (+2)",
    ionizationEnergy: "533",
    meltingPoint: "1294",
    name: "Neodymium",
    oxidationStates: "2, 3",
    standardState: "solid",
    symbol: "Nd",
    vanDelWaalsRadius: "",
    yearDiscovered: "1885",
  },
  {
    atomicMass: "145",
    atomicNumber: "61",
    atomicRadius: "",
    boilingPoint: "3273",
    bondingType: "metallic",
    cpkHexColor: "A3FFC7",
    density: "7.264",
    electronAffinity: "-50",
    electronegativity: "1.13",
    electronicConfiguration: "[Xe] 4f5 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "97 (+3)",
    ionizationEnergy: "540",
    meltingPoint: "1373",
    name: "Promethium",
    oxidationStates: "3",
    standardState: "solid",
    symbol: "Pm",
    vanDelWaalsRadius: "",
    yearDiscovered: "1947",
  },
  {
    atomicMass: "150.36(2)",
    atomicNumber: "62",
    atomicRadius: "",
    boilingPoint: "2076",
    bondingType: "metallic",
    cpkHexColor: "8FFFC7",
    density: "7.353",
    electronAffinity: "-50",
    electronegativity: "1.17",
    electronicConfiguration: "[Xe] 4f6 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "122 (+2)",
    ionizationEnergy: "545",
    meltingPoint: "1345",
    name: "Samarium",
    oxidationStates: "2, 3",
    standardState: "solid",
    symbol: "Sm",
    vanDelWaalsRadius: "",
    yearDiscovered: "1853",
  },
  {
    atomicMass: "151.964(1)",
    atomicNumber: "63",
    atomicRadius: "",
    boilingPoint: "1800",
    bondingType: "metallic",
    cpkHexColor: "61FFC7",
    density: "5.244",
    electronAffinity: "-50",
    electronegativity: "1.2",
    electronicConfiguration: "[Xe] 4f7 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "117 (+2)",
    ionizationEnergy: "547",
    meltingPoint: "1095",
    name: "Europium",
    oxidationStates: "2, 3",
    standardState: "solid",
    symbol: "Eu",
    vanDelWaalsRadius: "",
    yearDiscovered: "1901",
  },
  {
    atomicMass: "157.25(3)",
    atomicNumber: "64",
    atomicRadius: "",
    boilingPoint: "3523",
    bondingType: "metallic",
    cpkHexColor: "45FFC7",
    density: "7.901",
    electronAffinity: "-50",
    electronegativity: "1.2",
    electronicConfiguration: "[Xe] 4f7 5d1 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "93.8 (+3)",
    ionizationEnergy: "593",
    meltingPoint: "1586",
    name: "Gadolinium",
    oxidationStates: "1, 2, 3",
    standardState: "solid",
    symbol: "Gd",
    vanDelWaalsRadius: "",
    yearDiscovered: "1880",
  },
  {
    atomicMass: "158.92535(2)",
    atomicNumber: "65",
    atomicRadius: "",
    boilingPoint: "3503",
    bondingType: "metallic",
    cpkHexColor: "30FFC7",
    density: "8.219",
    electronAffinity: "-50",
    electronegativity: "1.2",
    electronicConfiguration: "[Xe] 4f9 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "92.3 (+3)",
    ionizationEnergy: "566",
    meltingPoint: "1629",
    name: "Terbium",
    oxidationStates: "1, 3, 4",
    standardState: "solid",
    symbol: "Tb",
    vanDelWaalsRadius: "",
    yearDiscovered: "1843",
  },
  {
    atomicMass: "162.500(1)",
    atomicNumber: "66",
    atomicRadius: "",
    boilingPoint: "2840",
    bondingType: "metallic",
    cpkHexColor: "1FFFC7",
    density: "8.551",
    electronAffinity: "-50",
    electronegativity: "1.22",
    electronicConfiguration: "[Xe] 4f10 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "107 (+2)",
    ionizationEnergy: "573",
    meltingPoint: "1685",
    name: "Dysprosium",
    oxidationStates: "2, 3",
    standardState: "solid",
    symbol: "Dy",
    vanDelWaalsRadius: "",
    yearDiscovered: "1886",
  },
  {
    atomicMass: "164.93032(2)",
    atomicNumber: "67",
    atomicRadius: "",
    boilingPoint: "2973",
    bondingType: "metallic",
    cpkHexColor: "00FF9C",
    density: "8.795",
    electronAffinity: "-50",
    electronegativity: "1.23",
    electronicConfiguration: "[Xe] 4f11 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "90.1 (+3)",
    ionizationEnergy: "581",
    meltingPoint: "1747",
    name: "Holmium",
    oxidationStates: "3",
    standardState: "solid",
    symbol: "Ho",
    vanDelWaalsRadius: "",
    yearDiscovered: "1878",
  },
  {
    atomicMass: "167.259(3)",
    atomicNumber: "68",
    atomicRadius: "",
    boilingPoint: "3141",
    bondingType: "metallic",
    cpkHexColor: "0",
    density: "9.066",
    electronAffinity: "-50",
    electronegativity: "1.24",
    electronicConfiguration: "[Xe] 4f12 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "89 (+3)",
    ionizationEnergy: "589",
    meltingPoint: "1770",
    name: "Erbium",
    oxidationStates: "3",
    standardState: "solid",
    symbol: "Er",
    vanDelWaalsRadius: "",
    yearDiscovered: "1842",
  },
  {
    atomicMass: "168.93421(2)",
    atomicNumber: "69",
    atomicRadius: "",
    boilingPoint: "2223",
    bondingType: "metallic",
    cpkHexColor: "00D452",
    density: "9.321",
    electronAffinity: "-50",
    electronegativity: "1.25",
    electronicConfiguration: "[Xe] 4f13 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "103 (+2)",
    ionizationEnergy: "597",
    meltingPoint: "1818",
    name: "Thulium",
    oxidationStates: "2, 3",
    standardState: "solid",
    symbol: "Tm",
    vanDelWaalsRadius: "",
    yearDiscovered: "1879",
  },
  {
    atomicMass: "173.054(5)",
    atomicNumber: "70",
    atomicRadius: "",
    boilingPoint: "1469",
    bondingType: "metallic",
    cpkHexColor: "00BF38",
    density: "6.57",
    electronAffinity: "-50",
    electronegativity: "1.1",
    electronicConfiguration: "[Xe] 4f14 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "102 (+2)",
    ionizationEnergy: "603",
    meltingPoint: "1092",
    name: "Ytterbium",
    oxidationStates: "2, 3",
    standardState: "solid",
    symbol: "Yb",
    vanDelWaalsRadius: "",
    yearDiscovered: "1878",
  },
  {
    atomicMass: "174.9668(1)",
    atomicNumber: "71",
    atomicRadius: "160",
    boilingPoint: "3675",
    bondingType: "metallic",
    cpkHexColor: "00AB24",
    density: "9.841",
    electronAffinity: "-50",
    electronegativity: "1.27",
    electronicConfiguration: "[Xe] 4f14 5d1 6s2",
    groupBlock: "lanthanoid",
    ionRadius: "86.1 (+3)",
    ionizationEnergy: "524",
    meltingPoint: "1936",
    name: "Lutetium",
    oxidationStates: "3",
    standardState: "solid",
    symbol: "Lu",
    vanDelWaalsRadius: "",
    yearDiscovered: "1907",
  },
  {
    atomicMass: "178.49(2)",
    atomicNumber: "72",
    atomicRadius: "150",
    boilingPoint: "4876",
    bondingType: "metallic",
    cpkHexColor: "4DC2FF",
    density: "13.31",
    electronAffinity: "0",
    electronegativity: "1.3",
    electronicConfiguration: "[Xe] 4f14 5d2 6s2",
    groupBlock: "transition metal",
    ionRadius: "71 (+4)",
    ionizationEnergy: "659",
    meltingPoint: "2506",
    name: "Hafnium",
    oxidationStates: "2, 3, 4",
    standardState: "solid",
    symbol: "Hf",
    vanDelWaalsRadius: "",
    yearDiscovered: "1923",
  },
  {
    atomicMass: "180.94788(2)",
    atomicNumber: "73",
    atomicRadius: "138",
    boilingPoint: "5731",
    bondingType: "metallic",
    cpkHexColor: "4DA6FF",
    density: "16.65",
    electronAffinity: "-31",
    electronegativity: "1.5",
    electronicConfiguration: "[Xe] 4f14 5d3 6s2",
    groupBlock: "transition metal",
    ionRadius: "72 (+3)",
    ionizationEnergy: "761",
    meltingPoint: "3290",
    name: "Tantalum",
    oxidationStates: "-1, 2, 3, 4, 5",
    standardState: "solid",
    symbol: "Ta",
    vanDelWaalsRadius: "",
    yearDiscovered: "1802",
  },
  {
    atomicMass: "183.84(1)",
    atomicNumber: "74",
    atomicRadius: "146",
    boilingPoint: "5828",
    bondingType: "metallic",
    cpkHexColor: "2194D6",
    density: "19.25",
    electronAffinity: "-79",
    electronegativity: "2.36",
    electronicConfiguration: "[Xe] 4f14 5d4 6s2",
    groupBlock: "transition metal",
    ionRadius: "66 (+4)",
    ionizationEnergy: "770",
    meltingPoint: "3695",
    name: "Tungsten",
    oxidationStates: "-2, -1, 1, 2, 3, 4, 5, 6",
    standardState: "solid",
    symbol: "W",
    vanDelWaalsRadius: "",
    yearDiscovered: "1783",
  },
  {
    atomicMass: "186.207(1)",
    atomicNumber: "75",
    atomicRadius: "159",
    boilingPoint: "5869",
    bondingType: "metallic",
    cpkHexColor: "267DAB",
    density: "21.02",
    electronAffinity: "-15",
    electronegativity: "1.9",
    electronicConfiguration: "[Xe] 4f14 5d5 6s2",
    groupBlock: "transition metal",
    ionRadius: "63 (+4)",
    ionizationEnergy: "760",
    meltingPoint: "3459",
    name: "Rhenium",
    oxidationStates: "-3, -1, 1, 2, 3, 4, 5, 6, 7",
    standardState: "solid",
    symbol: "Re",
    vanDelWaalsRadius: "",
    yearDiscovered: "1925",
  },
  {
    atomicMass: "190.23(3)",
    atomicNumber: "76",
    atomicRadius: "128",
    boilingPoint: "5285",
    bondingType: "metallic",
    cpkHexColor: "266696",
    density: "22.61",
    electronAffinity: "-106",
    electronegativity: "2.2",
    electronicConfiguration: "[Xe] 4f14 5d6 6s2",
    groupBlock: "transition metal",
    ionRadius: "63 (+4)",
    ionizationEnergy: "840",
    meltingPoint: "3306",
    name: "Osmium",
    oxidationStates: "-2, -1, 1, 2, 3, 4, 5, 6, 7, 8",
    standardState: "solid",
    symbol: "Os",
    vanDelWaalsRadius: "",
    yearDiscovered: "1803",
  },
  {
    atomicMass: "192.217(3)",
    atomicNumber: "77",
    atomicRadius: "137",
    boilingPoint: "4701",
    bondingType: "metallic",
    cpkHexColor: "175487",
    density: "22.65",
    electronAffinity: "-151",
    electronegativity: "2.2",
    electronicConfiguration: "[Xe] 4f14 5d7 6s2",
    groupBlock: "transition metal",
    ionRadius: "68 (+3)",
    ionizationEnergy: "880",
    meltingPoint: "2739",
    name: "Iridium",
    oxidationStates: "-3, -1, 1, 2, 3, 4, 5, 6",
    standardState: "solid",
    symbol: "Ir",
    vanDelWaalsRadius: "",
    yearDiscovered: "1803",
  },
  {
    atomicMass: "195.084(9)",
    atomicNumber: "78",
    atomicRadius: "128",
    boilingPoint: "4098",
    bondingType: "metallic",
    cpkHexColor: "D0D0E0",
    density: "21.09",
    electronAffinity: "-205",
    electronegativity: "2.28",
    electronicConfiguration: "[Xe] 4f14 5d9 6s1",
    groupBlock: "transition metal",
    ionRadius: "86 (+2)",
    ionizationEnergy: "870",
    meltingPoint: "2041",
    name: "Platinum",
    oxidationStates: "2, 4, 5, 6",
    standardState: "solid",
    symbol: "Pt",
    vanDelWaalsRadius: "175",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "196.966569(4)",
    atomicNumber: "79",
    atomicRadius: "144",
    boilingPoint: "3129",
    bondingType: "metallic",
    cpkHexColor: "FFD123",
    density: "19.3",
    electronAffinity: "-223",
    electronegativity: "2.54",
    electronicConfiguration: "[Xe] 4f14 5d10 6s1",
    groupBlock: "transition metal",
    ionRadius: "137 (+1)",
    ionizationEnergy: "890",
    meltingPoint: "1337",
    name: "Gold",
    oxidationStates: "-1, 1, 2, 3, 5",
    standardState: "solid",
    symbol: "Au",
    vanDelWaalsRadius: "166",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "200.59(2)",
    atomicNumber: "80",
    atomicRadius: "149",
    boilingPoint: "630",
    bondingType: "metallic",
    cpkHexColor: "B8B8D0",
    density: "13.534",
    electronAffinity: "0",
    electronegativity: "2",
    electronicConfiguration: "[Xe] 4f14 5d10 6s2",
    groupBlock: "transition metal",
    ionRadius: "119 (+1)",
    ionizationEnergy: "1007",
    meltingPoint: "234",
    name: "Mercury",
    oxidationStates: "1, 2, 4",
    standardState: "liquid",
    symbol: "Hg",
    vanDelWaalsRadius: "155",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "204.3833(2)",
    atomicNumber: "81",
    atomicRadius: "148",
    boilingPoint: "1746",
    bondingType: "metallic",
    cpkHexColor: "A6544D",
    density: "11.85",
    electronAffinity: "-19",
    electronegativity: "2.04",
    electronicConfiguration: "[Xe] 4f14 5d10 6s2 6p1",
    groupBlock: "metal",
    ionRadius: "150 (+1)",
    ionizationEnergy: "589",
    meltingPoint: "577",
    name: "Thallium",
    oxidationStates: "1, 3",
    standardState: "solid",
    symbol: "Tl",
    vanDelWaalsRadius: "196",
    yearDiscovered: "1861",
  },
  {
    atomicMass: "207.2(1)",
    atomicNumber: "82",
    atomicRadius: "147",
    boilingPoint: "2022",
    bondingType: "metallic",
    cpkHexColor: "575961",
    density: "11.34",
    electronAffinity: "-35",
    electronegativity: "2.33",
    electronicConfiguration: "[Xe] 4f14 5d10 6s2 6p2",
    groupBlock: "metal",
    ionRadius: "119 (+2)",
    ionizationEnergy: "716",
    meltingPoint: "601",
    name: "Lead",
    oxidationStates: "-4, 2, 4",
    standardState: "solid",
    symbol: "Pb",
    vanDelWaalsRadius: "202",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "208.98040(1)",
    atomicNumber: "83",
    atomicRadius: "146",
    boilingPoint: "1837",
    bondingType: "metallic",
    cpkHexColor: "9E4FB5",
    density: "9.78",
    electronAffinity: "-91",
    electronegativity: "2.02",
    electronicConfiguration: "[Xe] 4f14 5d10 6s2 6p3",
    groupBlock: "metal",
    ionRadius: "103 (+3)",
    ionizationEnergy: "703",
    meltingPoint: "544",
    name: "Bismuth",
    oxidationStates: "-3, 3, 5",
    standardState: "solid",
    symbol: "Bi",
    vanDelWaalsRadius: "",
    yearDiscovered: "Ancient",
  },
  {
    atomicMass: "209",
    atomicNumber: "84",
    atomicRadius: "",
    boilingPoint: "1235",
    bondingType: "metallic",
    cpkHexColor: "AB5C00",
    density: "9.196",
    electronAffinity: "-183",
    electronegativity: "2",
    electronicConfiguration: "[Xe] 4f14 5d10 6s2 6p4",
    groupBlock: "metalloid",
    ionRadius: "94 (+4)",
    ionizationEnergy: "812",
    meltingPoint: "527",
    name: "Polonium",
    oxidationStates: "-2, 2, 4, 6",
    standardState: "solid",
    symbol: "Po",
    vanDelWaalsRadius: "",
    yearDiscovered: "1898",
  },
  {
    atomicMass: "210",
    atomicNumber: "85",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "covalent network",
    cpkHexColor: "754F45",
    density: "",
    electronAffinity: "-270",
    electronegativity: "2.2",
    electronicConfiguration: "[Xe] 4f14 5d10 6s2 6p5",
    groupBlock: "halogen",
    ionRadius: "62 (+7)",
    ionizationEnergy: "920",
    meltingPoint: "575",
    name: "Astatine",
    oxidationStates: "-1, 1, 3, 5",
    standardState: "solid",
    symbol: "At",
    vanDelWaalsRadius: "",
    yearDiscovered: "1940",
  },
  {
    atomicMass: "222",
    atomicNumber: "86",
    atomicRadius: "145",
    boilingPoint: "211",
    bondingType: "atomic",
    cpkHexColor: "428296",
    density: "0.00973",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Xe] 4f14 5d10 6s2 6p6",
    groupBlock: "noble gas",
    ionRadius: "",
    ionizationEnergy: "1037",
    meltingPoint: "202",
    name: "Radon",
    oxidationStates: "2",
    standardState: "gas",
    symbol: "Rn",
    vanDelWaalsRadius: "",
    yearDiscovered: "1900",
  },
  {
    atomicMass: "223",
    atomicNumber: "87",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "metallic",
    cpkHexColor: "420066",
    density: "",
    electronAffinity: "",
    electronegativity: "0.7",
    electronicConfiguration: "[Rn] 7s1",
    groupBlock: "alkali metal",
    ionRadius: "180 (+1)",
    ionizationEnergy: "380",
    meltingPoint: "",
    name: "Francium",
    oxidationStates: "1",
    standardState: "solid",
    symbol: "Fr",
    vanDelWaalsRadius: "",
    yearDiscovered: "1939",
  },
  {
    atomicMass: "226",
    atomicNumber: "88",
    atomicRadius: "",
    boilingPoint: "2010",
    bondingType: "metallic",
    cpkHexColor: "007D00",
    density: "5",
    electronAffinity: "",
    electronegativity: "0.9",
    electronicConfiguration: "[Rn] 7s2",
    groupBlock: "alkaline earth metal",
    ionRadius: "148 (+2)",
    ionizationEnergy: "509",
    meltingPoint: "973",
    name: "Radium",
    oxidationStates: "2",
    standardState: "solid",
    symbol: "Ra",
    vanDelWaalsRadius: "",
    yearDiscovered: "1898",
  },
  {
    atomicMass: "227",
    atomicNumber: "89",
    atomicRadius: "",
    boilingPoint: "3473",
    bondingType: "metallic",
    cpkHexColor: "70ABFA",
    density: "10.07",
    electronAffinity: "",
    electronegativity: "1.1",
    electronicConfiguration: "[Rn] 6d1 7s2",
    groupBlock: "actinoid",
    ionRadius: "112 (+3)",
    ionizationEnergy: "499",
    meltingPoint: "1323",
    name: "Actinium",
    oxidationStates: "3",
    standardState: "solid",
    symbol: "Ac",
    vanDelWaalsRadius: "",
    yearDiscovered: "1899",
  },
  {
    atomicMass: "232.03806(2)",
    atomicNumber: "90",
    atomicRadius: "",
    boilingPoint: "5093",
    bondingType: "metallic",
    cpkHexColor: "00BAFF",
    density: "11.724",
    electronAffinity: "",
    electronegativity: "1.3",
    electronicConfiguration: "[Rn] 6d2 7s2",
    groupBlock: "actinoid",
    ionRadius: "94 (+4)",
    ionizationEnergy: "587",
    meltingPoint: "2023",
    name: "Thorium",
    oxidationStates: "2, 3, 4",
    standardState: "solid",
    symbol: "Th",
    vanDelWaalsRadius: "",
    yearDiscovered: "1828",
  },
  {
    atomicMass: "231.03588(2)",
    atomicNumber: "91",
    atomicRadius: "",
    boilingPoint: "4273",
    bondingType: "metallic",
    cpkHexColor: "00A1FF",
    density: "15.37",
    electronAffinity: "",
    electronegativity: "1.5",
    electronicConfiguration: "[Rn] 5f2 6d1 7s2",
    groupBlock: "actinoid",
    ionRadius: "104 (+3)",
    ionizationEnergy: "568",
    meltingPoint: "1845",
    name: "Protactinium",
    oxidationStates: "3, 4, 5",
    standardState: "solid",
    symbol: "Pa",
    vanDelWaalsRadius: "",
    yearDiscovered: "1913",
  },
  {
    atomicMass: "238.02891(3)",
    atomicNumber: "92",
    atomicRadius: "",
    boilingPoint: "4200",
    bondingType: "metallic",
    cpkHexColor: "008FFF",
    density: "19.05",
    electronAffinity: "",
    electronegativity: "1.38",
    electronicConfiguration: "[Rn] 5f3 6d1 7s2",
    groupBlock: "actinoid",
    ionRadius: "102.5 (+3)",
    ionizationEnergy: "598",
    meltingPoint: "1408",
    name: "Uranium",
    oxidationStates: "3, 4, 5, 6",
    standardState: "solid",
    symbol: "U",
    vanDelWaalsRadius: "186",
    yearDiscovered: "1789",
  },
  {
    atomicMass: "237",
    atomicNumber: "93",
    atomicRadius: "",
    boilingPoint: "4273",
    bondingType: "metallic",
    cpkHexColor: "0080FF",
    density: "20.45",
    electronAffinity: "",
    electronegativity: "1.36",
    electronicConfiguration: "[Rn] 5f4 6d1 7s2",
    groupBlock: "actinoid",
    ionRadius: "110 (+2)",
    ionizationEnergy: "605",
    meltingPoint: "917",
    name: "Neptunium",
    oxidationStates: "3, 4, 5, 6, 7",
    standardState: "solid",
    symbol: "Np",
    vanDelWaalsRadius: "",
    yearDiscovered: "1940",
  },
  {
    atomicMass: "244",
    atomicNumber: "94",
    atomicRadius: "",
    boilingPoint: "3503",
    bondingType: "metallic",
    cpkHexColor: "006BFF",
    density: "19.816",
    electronAffinity: "",
    electronegativity: "1.28",
    electronicConfiguration: "[Rn] 5f6 7s2",
    groupBlock: "actinoid",
    ionRadius: "100 (+3)",
    ionizationEnergy: "585",
    meltingPoint: "913",
    name: "Plutonium",
    oxidationStates: "3, 4, 5, 6, 7",
    standardState: "solid",
    symbol: "Pu",
    vanDelWaalsRadius: "",
    yearDiscovered: "1940",
  },
  {
    atomicMass: "243",
    atomicNumber: "95",
    atomicRadius: "",
    boilingPoint: "2284",
    bondingType: "metallic",
    cpkHexColor: "545CF2",
    density: "",
    electronAffinity: "",
    electronegativity: "1.3",
    electronicConfiguration: "[Rn] 5f7 7s2",
    groupBlock: "actinoid",
    ionRadius: "126 (+2)",
    ionizationEnergy: "578",
    meltingPoint: "1449",
    name: "Americium",
    oxidationStates: "2, 3, 4, 5, 6",
    standardState: "solid",
    symbol: "Am",
    vanDelWaalsRadius: "",
    yearDiscovered: "1944",
  },
  {
    atomicMass: "247",
    atomicNumber: "96",
    atomicRadius: "",
    boilingPoint: "3383",
    bondingType: "metallic",
    cpkHexColor: "785CE3",
    density: "13.51",
    electronAffinity: "",
    electronegativity: "1.3",
    electronicConfiguration: "[Rn] 5f7 6d1 7s2",
    groupBlock: "actinoid",
    ionRadius: "97 (+3)",
    ionizationEnergy: "581",
    meltingPoint: "1618",
    name: "Curium",
    oxidationStates: "3, 4",
    standardState: "solid",
    symbol: "Cm",
    vanDelWaalsRadius: "",
    yearDiscovered: "1944",
  },
  {
    atomicMass: "247",
    atomicNumber: "97",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "metallic",
    cpkHexColor: "8A4FE3",
    density: "14.78",
    electronAffinity: "",
    electronegativity: "1.3",
    electronicConfiguration: "[Rn] 5f9 7s2",
    groupBlock: "actinoid",
    ionRadius: "96 (+3)",
    ionizationEnergy: "601",
    meltingPoint: "1323",
    name: "Berkelium",
    oxidationStates: "3, 4",
    standardState: "solid",
    symbol: "Bk",
    vanDelWaalsRadius: "",
    yearDiscovered: "1949",
  },
  {
    atomicMass: "251",
    atomicNumber: "98",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "metallic",
    cpkHexColor: "A136D4",
    density: "15.1",
    electronAffinity: "",
    electronegativity: "1.3",
    electronicConfiguration: "[Rn] 5f10 7s2",
    groupBlock: "actinoid",
    ionRadius: "95 (+3)",
    ionizationEnergy: "608",
    meltingPoint: "1173",
    name: "Californium",
    oxidationStates: "2, 3, 4",
    standardState: "solid",
    symbol: "Cf",
    vanDelWaalsRadius: "",
    yearDiscovered: "1950",
  },
  {
    atomicMass: "252",
    atomicNumber: "99",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "B31FD4",
    density: "",
    electronAffinity: "",
    electronegativity: "1.3",
    electronicConfiguration: "[Rn] 5f11 7s2",
    groupBlock: "actinoid",
    ionRadius: "",
    ionizationEnergy: "619",
    meltingPoint: "1133",
    name: "Einsteinium",
    oxidationStates: "2, 3",
    standardState: "solid",
    symbol: "Es",
    vanDelWaalsRadius: "",
    yearDiscovered: "1952",
  },
  {
    atomicMass: "257",
    atomicNumber: "100",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "B31FBA",
    density: "",
    electronAffinity: "",
    electronegativity: "1.3",
    electronicConfiguration: "[Rn] 5f12 7s2",
    groupBlock: "actinoid",
    ionRadius: "",
    ionizationEnergy: "627",
    meltingPoint: "1800",
    name: "Fermium",
    oxidationStates: "2, 3",
    standardState: "",
    symbol: "Fm",
    vanDelWaalsRadius: "",
    yearDiscovered: "1952",
  },
  {
    atomicMass: "258",
    atomicNumber: "101",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "B30DA6",
    density: "",
    electronAffinity: "",
    electronegativity: "1.3",
    electronicConfiguration: "[Rn] 5f13 7s2",
    groupBlock: "actinoid",
    ionRadius: "",
    ionizationEnergy: "635",
    meltingPoint: "1100",
    name: "Mendelevium",
    oxidationStates: "2, 3",
    standardState: "",
    symbol: "Md",
    vanDelWaalsRadius: "",
    yearDiscovered: "1955",
  },
  {
    atomicMass: "259",
    atomicNumber: "102",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "BD0D87",
    density: "",
    electronAffinity: "",
    electronegativity: "1.3",
    electronicConfiguration: "[Rn] 5f14 7s2",
    groupBlock: "actinoid",
    ionRadius: "",
    ionizationEnergy: "642",
    meltingPoint: "1100",
    name: "Nobelium",
    oxidationStates: "2, 3",
    standardState: "",
    symbol: "No",
    vanDelWaalsRadius: "",
    yearDiscovered: "1957",
  },
  {
    atomicMass: "262",
    atomicNumber: "103",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "C70066",
    density: "",
    electronAffinity: "",
    electronegativity: "1.3",
    electronicConfiguration: "[Rn] 5f14 7s2 7p1",
    groupBlock: "transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "1900",
    name: "Lawrencium",
    oxidationStates: "3",
    standardState: "",
    symbol: "Lr",
    vanDelWaalsRadius: "",
    yearDiscovered: "1961",
  },
  {
    atomicMass: "267",
    atomicNumber: "104",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "CC0059",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d2 7s2",
    groupBlock: "transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Rutherfordium",
    oxidationStates: "4",
    standardState: "",
    symbol: "Rf",
    vanDelWaalsRadius: "",
    yearDiscovered: "1969",
  },
  {
    atomicMass: "268",
    atomicNumber: "105",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "D1004F",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d3 7s2",
    groupBlock: "transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Dubnium",
    oxidationStates: "",
    standardState: "",
    symbol: "Db",
    vanDelWaalsRadius: "",
    yearDiscovered: "1967",
  },
  {
    atomicMass: "271",
    atomicNumber: "106",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "D90045",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d4 7s2",
    groupBlock: "transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Seaborgium",
    oxidationStates: "",
    standardState: "",
    symbol: "Sg",
    vanDelWaalsRadius: "",
    yearDiscovered: "1974",
  },
  {
    atomicMass: "272",
    atomicNumber: "107",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "E00038",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d5 7s2",
    groupBlock: "transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Bohrium",
    oxidationStates: "",
    standardState: "",
    symbol: "Bh",
    vanDelWaalsRadius: "",
    yearDiscovered: "1976",
  },
  {
    atomicMass: "270",
    atomicNumber: "108",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "E6002E",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d6 7s2",
    groupBlock: "transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Hassium",
    oxidationStates: "",
    standardState: "",
    symbol: "Hs",
    vanDelWaalsRadius: "",
    yearDiscovered: "1984",
  },
  {
    atomicMass: "276",
    atomicNumber: "109",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "EB0026",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d7 7s2",
    groupBlock: "transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Meitnerium",
    oxidationStates: "",
    standardState: "",
    symbol: "Mt",
    vanDelWaalsRadius: "",
    yearDiscovered: "1982",
  },
  {
    atomicMass: "281",
    atomicNumber: "110",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d9 7s1",
    groupBlock: "transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Darmstadtium",
    oxidationStates: "",
    standardState: "",
    symbol: "Ds",
    vanDelWaalsRadius: "",
    yearDiscovered: "1994",
  },
  {
    atomicMass: "280",
    atomicNumber: "111",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d10 7s1",
    groupBlock: "transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Roentgenium",
    oxidationStates: "",
    standardState: "",
    symbol: "Rg",
    vanDelWaalsRadius: "",
    yearDiscovered: "1994",
  },
  {
    atomicMass: "285",
    atomicNumber: "112",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d10 7s2",
    groupBlock: "transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Copernicium",
    oxidationStates: "",
    standardState: "",
    symbol: "Cn",
    vanDelWaalsRadius: "",
    yearDiscovered: "1996",
  },
  {
    atomicMass: "284",
    atomicNumber: "113",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d10 7s2 7p1",
    groupBlock: "post-transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Nihonium",
    oxidationStates: "",
    standardState: "",
    symbol: "Nh",
    vanDelWaalsRadius: "",
    yearDiscovered: "2003",
  },
  {
    atomicMass: "289",
    atomicNumber: "114",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d10 7s2 7p2",
    groupBlock: "post-transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Flerovium",
    oxidationStates: "",
    standardState: "",
    symbol: "Fl",
    vanDelWaalsRadius: "",
    yearDiscovered: "1998",
  },
  {
    atomicMass: "288",
    atomicNumber: "115",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d10 7s2 7p3",
    groupBlock: "post-transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Moscovium",
    oxidationStates: "",
    standardState: "",
    symbol: "Mc",
    vanDelWaalsRadius: "",
    yearDiscovered: "2003",
  },
  {
    atomicMass: "293",
    atomicNumber: "116",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d10 7s2 7p4",
    groupBlock: "post-transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Livermorium",
    oxidationStates: "",
    standardState: "",
    symbol: "Lv",
    vanDelWaalsRadius: "",
    yearDiscovered: "2000",
  },
  {
    atomicMass: "294",
    atomicNumber: "117",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d10 7s2 7p5",
    groupBlock: "post-transition metal",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Tennessine",
    oxidationStates: "",
    standardState: "",
    symbol: "Ts",
    vanDelWaalsRadius: "",
    yearDiscovered: "2010",
  },
  {
    atomicMass: "294",
    atomicNumber: "118",
    atomicRadius: "",
    boilingPoint: "",
    bondingType: "",
    cpkHexColor: "",
    density: "",
    electronAffinity: "",
    electronegativity: "",
    electronicConfiguration: "[Rn] 5f14 6d10 7s2 7p6",
    groupBlock: "noble gas",
    ionRadius: "",
    ionizationEnergy: "",
    meltingPoint: "",
    name: "Oganesson",
    oxidationStates: "",
    standardState: "",
    symbol: "Og",
    vanDelWaalsRadius: "",
    yearDiscovered: "2002",
  },
];
