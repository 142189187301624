export const SELECT_ELEMENT = "SELECT_ELEMENT";
export const FETCH_TABLE = "FETCH_TABLE";
export const SELECT_CHEMICAL_GROUP = "SELECT_CHEMICAL_GROUP";
export const CLEAR_FORM = "CLEAR_FORM";
export const HIDE_ALERT = "HIDE_ALERT";
export const SHOW_ALERT = "SHOW_ALERT";
export const CHANGE_MODE = "CHANGE_MODE";
export const START_PRACTICE = "START_PRACTICE";
export const NEXT_QUESTION = "NEXT_QUESTION";
export const CHECK_ANSWER = "CHECK_ANSWER";
export const UPDATE_PROGESS = "UPDATE_PROGRESS";
export const END_PRACTICE = "END_PRACTICE";
