import React from 'react';

const Form = (props) => {
  return(
    <>
    {props.children}
    </>
  );
}

export default Form;